import { h } from 'preact'

// Tell transpiler to transform JSX into h() calls:
/** @jsx h */

const NpoSelect = props => {
  const handleChange = e => {
    const params = { attributes: { Nonprofit: e.target.value } }
    fetch('/cart/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }).then(response => {
      if (response.ok) {
        if (window.__debug_plg)
          response.json().then(({ attributes }) =>
            console.log('Updated nonprofit', attributes)
          )
      } else
        console.error('Updating nonprofit failed', response)
    }).catch(error => console.error('Updating nonprofit failed', error))
  }

  return (
    <div style={{ marginBottom: '20px' }}>
      <label htmlFor="plgNonprofit">
        { props.label || 'Choose the nonprofit your donation will go to' }
      </label>
      <div className="select">
        <select
          id="plgNonprofit"
          name="attributes[Nonprofit]"
          onChange={handleChange}
          className="select__select"
          style={{ fontSize: 'inherit' }}
        >
          <option value="">
            { props.splitLabel || 'Split donation among nonprofits' }
          </option>
          <optgroup label="Nonprofits">
            {props.organizations.map(organization =>
              <option key={organization.ein} value={organization.ein}>
                {organization.name}
              </option>
            )}
          </optgroup>
        </select>
        <svg
          aria-hidden="true"
          focusable="false"
          className="icon-caret hide-this-icon-on-legacy-themes"
          viewBox="0 0 10 6"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  )
}

export default NpoSelect
