import MutationSummary  from 'mutation-summary'
import { h, render }    from 'preact'

import sliceObj from 'src/utils/sliceObj'

import NpoSelect from './NpoSelect'

const NPOS_ATTR = 'data-select-nonprofit-via-pledgeling'
const LOAD_ATTR = 'data-stay-back-this-npo-select-is-loaded'
const PROPS     = [ 'label', 'splitLabel' ]

const script = document.currentScript
const url = new URL(script.src, location)
const origin = url.origin
const selector = `[${NPOS_ATTR}]`

const renderNpoSelect = (element, data) => {
  if (element.getAttribute(LOAD_ATTR)) return
  element.setAttribute(LOAD_ATTR, '1')

  const props = { ...sliceObj(element.dataset, PROPS), ...data }
  render(h(NpoSelect, props), element)
}

const hideIconOnLegacyThemes = () => {
  const firstStyleSheet = document.querySelector('style, link[rel="stylesheet"]')
  if (!firstStyleSheet) return
  const style = document.createElement('style')
  style.textContent = '.hide-this-icon-on-legacy-themes { height: 0 }'
  firstStyleSheet.parentNode.insertBefore(style, firstStyleSheet)
}

export const init = () => {
  const shop = typeof Shopify === 'object' && Shopify.shop || location.host
  const url = `${origin}/shop/info/nonprofit_select?shop=${encodeURIComponent(shop)}`
  const success = ({ shop }) => {
    hideIconOnLegacyThemes()

    const renderElementWithData = element => {
      if (shop?.organizations?.length > 1)
        renderNpoSelect(element, shop)
      else if (element.hasAttribute('data-friendly-errors'))
        element.textContent = 'Your current donation settings are incompatible with customer’s choice'
    }

    document.querySelectorAll(selector).forEach(renderElementWithData)

    new MutationSummary({
      callback (summaries) {
        summaries[0].added.forEach(renderElementWithData)
      },
      queries: [{ element: selector }]
    })
  }

  fetch(url, { headers: { Accept: 'application/json' } })
  .then(response => response.json()).then(success)
  .catch(error => console.error('Fetching nonprofit list failed', error))
}
